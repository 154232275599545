import PropTypes from 'prop-types';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';

import { YA_CLICK_ID, YA_CONTENT_GOAL_TYPE } from 'site/constants';

const getGoalParams = host => {
  switch (true) {
    case host.endsWith('myglo.ru'):
      return {
        id: 'GloClick',
        level2Key: 'glo_click',
      };
    case host.endsWith('vuse.ru'):
      return {
        id: 'VuseClick',
        level2Key: 'vuse_click',
      };
    case host.endsWith('dunhilltobacco.ru'):
      return {
        id: 'DunhillClick',
        level2Key: 'dunhill_click',
      };
    default:
      return undefined;
  }
};


function sendEvent(e, goalType, customClickType) {
  const closestLink = e.target.closest('a');

  if (!closestLink) return;

  const isContentLink = goalType === YA_CONTENT_GOAL_TYPE;
  const goalParams = isContentLink
    ? getGoalParams(closestLink.host)
    : { level2Key: customClickType };
  const { id, level2Key } = goalParams || {};

  if (!level2Key) return;

  const level3Key = isContentLink
    ? 'link'
    : closestLink.text;
  const level4Key = isContentLink
    ? closestLink.href
    : 'null';

  const goalValue = {
    [goalType]: {
      [level2Key]: {
        [level3Key]: {
          [level4Key]: {
            page_url: document.URL,
          },
        },
      },
    },
  };

  callYaMetrikaMethod(
    YA_CLICK_ID,
    isContentLink ? 'reachGoal' : 'params',
    ...[id, goalValue].filter(Boolean)
  );
}


function YaMetrikaClickSender(props) {
  const {
    goalType,
    clickType,
    children,
  } = props;

  // TODO: обернуть хендлер в useCallback после переезда на новый резолвинг,
  // Пока useCallback не заюзать без skip, а он ломает SSR
  return <div onClickCapture={e => sendEvent(e, goalType, clickType)}>{children}</div>;
}

YaMetrikaClickSender.propTypes = {
  goalType: PropTypes.oneOf([YA_CONTENT_GOAL_TYPE, 'navigation']).isRequired,
  clickType: (props, propName, componentName) => {
    if (props.goalType !== YA_CONTENT_GOAL_TYPE && typeof props[propName] !== 'string') {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. ' +
        'You must provide `' + propName + '` when `goalType` is not `' + YA_CONTENT_GOAL_TYPE + '`. ' +
        'Events will not be sent.'
      );
    }
    return null;
  },
};

export default YaMetrikaClickSender;
