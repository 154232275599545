export const host = 'yourchoice.ru';

// JSON Web token для аутентификации пользователя
export const JWT_ACCESS_TOKEN = 'jwt-access-token';

// indents
export const SIDE_INDENT = 50;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 40;
export const VERTICAL_SMALL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 20;
export const INDENT = 20;

// limits
export const RUBRICS_OR_TAGS_LIMIT = 16;
export const RUBRICS_OR_TAGS_LIMIT_MOBILE = 8;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 640;
export const CONTENT_AREA_SPEC = 600;
export const RIGHT_COLUMN_WIDTH = 300;
export const CONTENT_MAX_WIDTH = LAYOUT_MAX_WIDTH - 2 * SIDE_INDENT;

// hashes
export const LOGIN_FORM_HASH = '#login_form';

export const SUBSCRIPTION_IS_HIDDEN_BY_USER = 'SUBSCRIPTION_IS_HIDDEN_BY_USER';
export const SUBSCRIPTION_IS_VERIFIED = 'SUBSCRIPTION_IS_VERIFIED';

// cookies
export const IS_ADULT = 'isAdult';
export const COOKIE_SPEC_GO = 'specGo';

export const RE_CAPTCHA_SITE_KEY = '6Ldkeh4eAAAAAJmNucJZ3wSgwTMKpojHMko6Z7kU';

export const SUBSCRIPTION_FORM_ID = 'subscriptionForm';

export const YA_CLICK_ID = 72679984;
export const YA_CONTENT_GOAL_TYPE = 'click_to_websites';

export const METRICS = {
  agegate_under18: 'agegate_under18',
  agegate_over18: 'agegate_over18',
  subscribe_popup_showed: 'subscribe_popup_showed',
  subscribe_popup_closed: 'subscribe_popup_closed',
  subscribe_form_submit: 'subscribe_form_submit',
  inpagesubscribe_form_submit: 'inpagesubscribe_form_submit',
  mail_subscribed: 'mail_subscribed',
  menu_reg_button_click: 'menu_reg_button_click',

  reg_popup_showed: 'reg_popup_showed',
  zen_popup_showed: 'zen_popup_showed',
  social_popup_showed: 'social_popup_showed',

  reg_popup_closed: 'reg_popup_closed',
  zen_popup_closed: 'zen_popup_closed',
  social_popup_closed: 'social_popup_closed',

  reg_popup_click: 'reg_popup_click',
  zen_popup_click: 'zen_popup_click',
  social_popup_clicked_fb: 'social_popup_clicked_fb',
  social_popup_clicked_vk: 'social_popup_clicked_vk',
  social_popup_clicked_ig: 'social_popup_clicked_ig',

  reg_form_user_exist: 'reg_form_user_exist',
  reg_form_sms_sent: 'reg_form_sms_sent',
  reg_form_phone_confirm: 'reg_form_phone_confirm',
  reg_form_registered: 'reg_form_registered',

  regwall_more_material_click: 'regwall_more_material_click',
  regwall_registration_click: 'regwall_registration_click',

  header_signin_click: 'header_signin_click',
  signin_success: 'signin_success',

  submit_reg_bonus: 'submit_reg_bonus',
};

/**
 * Спецпроект сертификат 100 рублей на озон.
 * Карта location.search параметров,
 * для передачи в запрос поля subsource_name.
 */
export const OZON_SUBSOURCE_MAP = {
  sp_travelask_ozon: 'sp_travelask_ozon',
  'sp_sorry-guys_ozon': 'sp_sorry-guys_ozon',
  banner_yc_ozon: 'banner_yc_ozon',
  paid_yabbi: 'banner_atl_ozon',
  paid_yabbi_ofd: 'banner_atl_ozon',
  paid_yabbi_banks: 'banner_atl_ozon',
  paid_mediatoday: 'banner_atl_ozon',
  paid_plazkart: 'banner_atl_ozon',
  paid_adsheads: 'banner_atl_ozon',
  paid_otclick: 'banner_atl_ozon',
  paid_cityworld: 'banner_atl_ozon',
  paid_redllama: 'banner_atl_ozon',
  paid_otm: 'banner_atl_ozon',
  paid_mywaymag: 'banner_atl_ozon',
  paid_travelask: 'banner_atl_ozon',
  paid_videonow: 'banner_atl_ozon',
  paid_videotarget: 'banner_atl_ozon',
  paid_soloway: 'banner_atl_ozon',
  paid_genuisaudience: 'banner_atl_ozon',
  paid_astralab: 'banner_atl_ozon',
  defaultFallback: 'yc_sp_other_ozon',
};

/**
 * Спецпроект 100 рублей на мобильный.
 * Карта location.search параметров,
 * для передачи в запрос поля subsource_name.
 */
export const PHONE_SUBSOURCE_MAP = {
  sp_travelask_phone: 'sp_travelask_phone',
  'sp_sorry-guys_phone': 'sp_sorry-guys_phone',
  banner_yc_phone: 'banner_yc_phone',
  paid_yabbi: 'banner_atl_phone',
  paid_yabbi_ofd: 'banner_atl_phone',
  paid_yabbi_banks: 'banner_atl_phone',
  paid_mediatoday: 'banner_atl_phone',
  paid_plazkart: 'banner_atl_phone',
  paid_adsheads: 'banner_atl_phone',
  paid_otclick: 'banner_atl_phone',
  paid_cityworld: 'banner_atl_phone',
  paid_redllama: 'banner_atl_phone',
  paid_otm: 'banner_atl_phone',
  paid_mywaymag: 'banner_atl_phone',
  paid_travelask: 'banner_atl_phone',
  paid_videonow: 'banner_atl_phone',
  paid_videotarget: 'banner_atl_phone',
  paid_soloway: 'banner_atl_phone',
  paid_genuisaudience: 'banner_atl_phone',
  paid_astralab: 'banner_atl_phone',
  defaultFallback: 'yc_sp_other_phone',
};
